<template>
  <el-dialog title="企业设置"
             width="30%"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible">
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="企业手机号码"
                    prop="tel">
        <el-input v-model="form.tel"
                  placeholder="请输入手机号码"
                  :disabled="form.id?true:false"></el-input>
      </el-form-item>
      <el-form-item label="所属员工手机号码"
                    prop="p_user_id">
        <el-select class="width100"
                   v-model="form.p_user_id"
                   filterable
                   remote
                   placeholder="请输入关键词"
                   :remote-method="remoteMethod"
                   :loading="selectLoading">
          <el-option v-for="item in options"
                     :key="item.value"
                     :label="item.label"
                     :value="item.value">
            <span>{{`(${item.name})${item.label}`}}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="resetForm('form')">重置</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.user_id?'修改':'立即创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

import { fetchEmployees } from '@/api/system'
import { setCompany, changeEmployeeTel } from '@/api/merchant'
export default {
  props: {
  },
  data () {
    return {
      form: {
        tel: '',
        p_user_id: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        p_user_id: [
          { required: true, type: 'number', message: '请选择所属员工号码', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  components: {
  },
  methods: {
    async remoteMethod (query) {
      if (query !== '' && query.length > 4) {
        this.selectLoading = true
        const res = await fetchEmployees({
          page: 1,
          size: 5,
          search_type: 'TEL',
          search_keyword: query
        })
        this.selectLoading = false
        if (res.meta.code === 0) {
          const list = []
          res.data.marketers.map((item) => {
            list.push({
              value: item.id,
              label: item.tel,
              name: item.name
            })
          })
          this.options = list
        }
      } else {
        this.options = []
      }
    },
    handleClose () { },
    showModel (formData) {
      this.dialogVisible = true
      if (formData) {
        this.form = Object.assign({}, formData)
        this.remoteMethod(formData.marketer_tel)
      } else {
        this.form = {
          tel: '',
          p_user_id: ''
        }
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {}
          if (this.form.id) {
            params = {
              user_id: this.form.id,
              p_user_id: this.form.p_user_id
            }
            const res = await changeEmployeeTel(params)
            if (res.meta.code === 0) {
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.$emit('on-success')
            } else {
              this.$alert(res.meta.msg, {
                type: 'error',
                content: res.meta.msg
              })
            }
          } else {
            params = this.form
            const res = await setCompany(params)
            if (res.meta.code === 0) {
              this.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success'
              })
              this.dialogVisible = false
              this.$emit('on-success')
            } else {
              this.$alert(res.meta.msg, {
                type: 'error',
                content: res.meta.msg
              })
            }
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
</style>
