<template>
  <div class="container">
    <table-page dataName="generals"
                :search="search"
                ref="table"
                :request="fetchCompanies">
      <template slot="button">
        <el-button type="primary"
                   class="mt-15"
                   @click="addCompany">新增企业</el-button>
      </template>
      <el-table-column prop="tel"
                       label="手机号码"
                       width="180">
      </el-table-column>
      <el-table-column prop="relate_merchant_total"
                       label="关联商户数"
                       width="180">
      </el-table-column>
      <el-table-column prop="marketer_tel"
                       label="所属员工手机号">
      </el-table-column>
      <el-table-column prop="marketer_city"
                       label="地市">
      </el-table-column>
      <el-table-column prop="marketer_net"
                       label="网点">
      </el-table-column>
      <el-table-column prop="marketer_district"
                       label="区县">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="editCompany(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delCompany(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <set-company ref="form"
                 @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import { fetchCompanies, delCompany } from '@/api/merchant'
import SetCompany from './components/SetCompany'
export default {
  name: 'Companies',
  data () {
    return {
      fetchCompanies,
      search: [{
        type: 'select',
        name: '搜索类型',
        value: '',
        key: 'search_type',
        list: [
          { value: 'G_TEL', label: '企业手机号码' },
          { value: 'M_TEL', label: '所属员工手机号码' }
        ]
      }, {
        type: 'input',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    SetCompany
  },
  methods: {
    addCompany () {
      this.$refs.form.showModel()
    },
    editCompany (row) {
      this.$refs.form.showModel(row)
    },
    delCompany (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delCompany({
          user_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    }

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
